import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { StaticQuery, graphql } from 'gatsby';
import { renderMobileCovers } from '../components/recipeCoversList';
import Logo from '../components/logo';

export const query = graphql`
  query OstatniePageCovers {
    allPrismicRecipe(limit: 6, sort: {fields: data___publication_date, order: DESC}) {
      edges {
        node {
          data {
            name
            slug
            serves
            cover_image {
              alt
              copyright
              url
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

const OstatniePage = () => (
  <Layout>
    <SEO title="Strona główna" />
    <header>
      <Logo />
    </header>
    <h1 className="section-header">Ostatnie przepisy</h1>
    <h3 className="section-header">Kliknij wybrany, aby zobaczyć</h3>
    <StaticQuery query={query} render={renderMobileCovers} />
  </Layout>
);

export default OstatniePage;
